




























import mixins from 'vue-typed-mixins'
import DefaultButton from '@/calendesk/sections/section/components/DefaultButton.vue'
import CPhoneInput from '@/views/components/CPhoneInput.vue'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import APIClient from '@/calendesk/api/APIClient'
import GeneralEmailMessageRequestData from '@/calendesk/models/DTO/Request/GeneralEmailMessageRequestData'
import FormElements from '@/calendesk/sections/section/shared/components/FormElements.vue'
import DraftElement from '@/calendesk/sections/section/mixins/DraftElement'

export default mixins(DraftElement).extend({
  name: 'FlexibleFormFields',
  props: {
    configuration: {
      type: Object,
      required: true
    },
    button: {
      type: Object,
      required: true
    }
  },
  components: { FormElements, CPhoneInput, DefaultButton },
  data () {
    return {
      isLoading: false,
      formData: [] as Array<Record<string, any>>,
      successMessage: null
    }
  },
  computed: {
    formElements (): Array<Record<string, any>> {
      return this.configuration.wb_form__dynamic_form__
    }
  },
  methods: {
    handleSend () {
      if ((this.$refs.Form as any).validate()) {
        let htmlContent = '<!DOCTYPE html><html><head><meta charset="utf-8"></head>'

        if (this.user) {
          htmlContent += `<b>${this.$trans('number')}:</b> ${this.user.id}<br>`
          htmlContent += `<b>${this.$trans('name_and_surname')}:</b> ${this.user.getFullName()}<br>`
          htmlContent += `<b>${this.$trans('email')}:</b> ${this.user.email}`

          if (this.user.defaultPhone) {
            htmlContent += `<br><b>${this.$trans('phone')}:</b> ${this.user.defaultPhone.e164}`
          }

          htmlContent += '<br><br><hr style="border: 1px dashed #f4f4f4"><br><br>'
        }

        if (this.formData && this.formData.length > 0) {
          this.formData.forEach((element: Record<string, any>) => {
            if (element.value) {
              if (element.description) {
                htmlContent += `<b>${element.description}</b><br>`
              } else {
                htmlContent += `<b>${element.label}</b><br>`
              }

              if (element.type === this.dynamicFormTypes.CHECKBOX) {
                htmlContent += `${element.label}<br><br>`
              } else {
                htmlContent += `${element.value}<br><br>`
              }
            }
          })
        }

        htmlContent += '</html>'

        this.isLoading = true

        const subject = this.configuration.wb_email_subject__text__

        APIClient.sendGeneralEmailMessage(new GeneralEmailMessageRequestData(subject, htmlContent)).then(() => {
          if (this.configuration.wb_email_form_success_url__url__) {
            window.location.href = this.configuration.wb_email_form_success_url__url__
          } else {
            this.successMessage = this.configuration.wb_email_sent__html_text__
          }
        }).catch((error) => {
          errorNotification(null, error)
        }).finally(() => {
          this.isLoading = false
        })
      } else {
        errorNotification('form_is_invalid', null, false)
      }
    }
  }
})
